import React, {useEffect} from 'react'
import Nav from '../components/nav'
import Footer from '../components/footer'
import {gsap} from 'gsap'
import '../stylings/contact.css'

// Assets
import {ReactComponent as BackgroundBase} from '../assets/background_colorful_v3.svg'
import {ReactComponent as BackgroundCutout} from '../assets/background_colorful_hand_v3.svg'
import {ReactComponent as Letters} from "../assets/contact_letters.svg";
import {Helmet} from "react-helmet";
// import {ReactComponent as DownArrow} from "../assets/down_arrow.svg";


const ContactUs = () => {
	useEffect(() => {
		let opening_home_animation = gsap.timeline({})
		opening_home_animation.from('.PageTitle', {
			display: "none",
			duration: 2,
			opacity: 0,
			delay: .5,
			ease: "slow(0.7, 0.7, false)"
		})
		opening_home_animation.from('.PageSubTitle', {
			display: "none",
			duration: 1,
			opacity: 0,
			ease: "slow(0.7, 0.7, false)"
		}, "-=1.5")
		opening_home_animation.from(['.NavButton', '.ArrowButton'], {
			display: "none",
			duration: 1,
			opacity: 0,
			y: () => Math.random() * 200,
			stagger: .25,
			ease: "slow(0.7, 0.7, false)"
		}, "-=.5")

		// Get size length of text
	// 	const logo = document.querySelectorAll("#contact_letters path");
	//
	// 	for (let i=0; i<logo.length; i++) {
	// 		console.log(i + ": " + logo[i].getTotalLength());
	// 	}
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Contact Us</title>
				<meta name="description" content="Invictus BCI -- email us at: contact@invictusbci.com" />
			</Helmet>

			{/*Background Images*/}
			<div className={"BackgroundLayers"}>
				<BackgroundBase className={"PageBackgroundBase"}/>
				<div className={'ContactAccentLayer AccentLayer'}/>
				<BackgroundCutout className={"PageBackgroundCutout"}/>
			</div>

			{/*Title Page*/}
			<div className={'ContactGridLayer MainPageLayer'}>
				<Nav/>
				<div className={'PageTitle'}>
					<Letters/>
				</div>
				{/*<a className={'ArrowButton'} href="#section2">*/}
				{/*	<DownArrow href="#section2" className={'MovingDownArrow'}/>*/}
				{/*</a>*/}
				{/*<div className={'ContactBodyPage'} id="section2">*/}
				{/*</div>*/}
				<div className={'PageSubTitle'}>
					Email us at: <a href="mailto:contact@invictusbci.com">contact@invictusbci.com</a>
				</div>
				<Footer/>
			</div>
		</>
	)
}

export default ContactUs
